import React, {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import useStore from 'store'
import {ModuleEnum, ModuleType, ProviderEnum} from 'utils/types'
import {useTranslation} from 'react-i18next'
import {Space, Row, Icon, Text, theme} from '../../ui'
import { Switch } from '@material-ui/core'
import {
    ModalRow,
    InfoWrapper,
    ModalText,
    ConfirmButtonSm,
    DeleteButtonSm,
    ButtonGroup,
    DialogHeaderText
} from './styles'


export interface VideoFormState {
    isEnable: boolean
}

interface VideoSectionProps {
    currentOrgId: number | undefined
    resetForm: boolean
}


const VideoSection: FC<VideoSectionProps> = ({ currentOrgId, resetForm }) => {
    const {modulesStore, orgs: orgsStore} = useStore()
    const {t} = useTranslation('communitySettings')

    const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
    const [module, setModule] = useState<any>(null)
    const [formState, setFormState] = useState<VideoFormState>({
        isEnable: false
    }) 

    useEffect(() => {
        const videoModule = modulesStore.communityFeatures.find(el => el.featureName === 'Video')
        if (videoModule) {
            setModule(videoModule)
            setFormState({ isEnable: videoModule.enabled })
        } else {
            orgsStore.updateCommunity(currentOrgId, [...modulesStore.communityFeatures, {
                communityId: currentOrgId,
                enabled: false,
                featureName: 'Video',
                id: 8,
                status: 2,
            }]).then(async (res) => {
                await modulesStore.getCommunityModulesAndFeatures(currentOrgId)
                const newVideoModule = modulesStore.communityFeatures.find(el => el.featureName === 'Video')
                if (newVideoModule) {
                    setModule(newVideoModule)
                    setFormState({ isEnable: newVideoModule.enabled })
                }
            })
        }
    }, [currentOrgId])

    useEffect(() => {
        if (resetForm) onResetForm()
    }, [resetForm])

    useEffect(() => {
        setIsFormChanged(
            formState.isEnable !== (module?.enabled ?? false)
        )
    }, [formState, module])


    const Save = async () => {
        if (currentOrgId) {
            await orgsStore.updateCommunity(currentOrgId,
                modulesStore.communityFeatures.map(el => el.featureName === 'Video' ? {
                    communityId: el.communityId,
                    enabled: formState.isEnable,
                    featureName: el.featureName,
                    id: el.id,
                    status: formState.isEnable ? 1 : 2,
                } : el),)

            await modulesStore.getCommunityModulesAndFeatures(currentOrgId)
            
            const videoModule = modulesStore.communityFeatures.find(el => el.featureName === 'Video')
            if (videoModule) {
              setModule(videoModule)
              setFormState({ isEnable: videoModule.enabled })
            }
        }        
    }
    
    const onResetForm = () => setFormState({
        isEnable: false
    })

    const ToggleEnable = (value: any) => setFormState({...formState, isEnable: value })

    return (
        <>
            <ModalRow>
               <DialogHeaderText>{`שליחת וידאו`}</DialogHeaderText>
               <ButtonGroup>
                 <ConfirmButtonSm disabled={!isFormChanged || module == null} onClick={Save} transparent>{t`save`}</ConfirmButtonSm>
               </ButtonGroup>
            </ModalRow>

            {module && 
                <>
                <ModalRow>
                    <InfoWrapper>
                        <ModalText>{t`isEnable`}:</ModalText>
                        <Space/>
                        <Switch
                            color="primary"
                            value={formState.isEnable}
                            onChange={({target}) => {
                                ToggleEnable(target.checked)
                            }}
                            checked={formState.isEnable}
                        />
                    </InfoWrapper>
                </ModalRow>
            </>
            }
            <Space/>
            <Space/>
        </>
    )
}

export default observer(VideoSection)