import React, { FC, useState } from 'react'
import { Avatar, Button, ListItem, Tooltip, Typography } from '@material-ui/core'
import { Done, TimerOff, Create, Send, EditLocationOutlined } from '@material-ui/icons'
import useStore from 'store'
import { Row, Space, theme } from 'ui'
import Text from 'ui/Text/Text'
import { extractMessagePayload, getTitleForDeliveryType } from 'utils/messageUtils'
import type { Message, UserMessage } from 'utils/models'
import { format, isAfter, isDate, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import MessageItemActions from './MessageItemActions'
import MesageStatus from './MesageStatus'
import PdfPreview from 'components/Messages/PdfPreview'
import LinkText from 'components/Messages/LinkText'
import VideoViewer from 'components/Messages/VideoViewer'
import SurveyMessageContainer from 'components/Messages/Survey/SurveyMessageContainer'
import SurveyProgress from 'components/Messages/Survey/SurveyProgress'
import { DeliveryType } from '../MessagesDialog'
import { observer } from 'mobx-react'
import { GroupType } from 'utils/types'
import isMobile from 'utils/isMobile'

const CreatedIcon = styled(Done)`
  margin: 0 0.2rem;
  font-size: ${theme.fontSize.small};
  color: ${theme.color.green};
`

const LocationIcon = styled(EditLocationOutlined)`
  margin-top: 0.5rem;
  margin-right: 0.2rem;
  font-size: ${theme.fontSize.small};
`

const ExpiryIcon = styled(TimerOff)`
  margin: 0 0.2rem;
  font-size: ${theme.fontSize.small};
  color: ${theme.color.gray};
`

const MessageListItem = styled(ListItem) <{ supportCallType: boolean, owner: boolean }>`
 word-break: break-word;

// TODO: uncomment to align based on message ownership
  justify-content:${p => (p.owner && p.supportCallType) ? 'flex-end' : 'flex-start'};
`

const MessageFileAvatar = styled(Avatar) <{ media?: 'IMAGE' | 'PDF' | 'VIDEO' }>`
  width: ${p => p.media === 'IMAGE' ? '150px' : '50px'};
  height: ${p => p.media === 'IMAGE' ? '150px' : '50px'};
`
const StyledImage = styled.img`
z-index:10px;
  width:100%;
  /* max-width:500px; */
  height: 100%;
  min-height:300px;
  max-height:300px;
  object-fit:cover;
  :hover {
    cursor: pointer;
  }
`
const MessageContent = styled.div<{ owner: string }>`
  display:flex;
  flex-direction: column;
  padding: 0.5rem;
  border:1px solid ${theme.color.whiteBackground + 80};
  border-radius: 10px;
  max-width:100%;
`
const StyledLinkText = styled.div`
  &>p{
    white-space: pre-wrap;
  }
`
const MessageContentContainer = styled.div<{ isVideo?: boolean, isTypeMedia: boolean, fullWidth?: boolean, supportCallType: boolean, owner: boolean }>`
  background-color: ${p => (p.owner && p.supportCallType) ? theme.color.serviceCallMessageBackLightGreen : theme.color.whiteBackground};
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  flex-wrap:wrap;
  max-width:${p => p.fullWidth ? '100%' : p.isTypeMedia ? '50%' : '65%'};
  flex-grow:${p => p.fullWidth ? 1 : p.isTypeMedia  && !p.isVideo ? 1 : 0};
  box-shadow: 0 2px 8px 0 ${theme.color.shadow};
  margin-left:20px;

  @media(max-width: 768px) {
    max-width: 100%;
    width:100%;
  }

`

const MessageHeader = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Footer = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`


const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ExpireContainer = styled(StyledRow)`
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`

const StatusText = styled(Text)`
  line-height: 10px;
  direction: ltr;
  padding: 0;
`

const AuthorText = styled(Text)`
  padding-right: 2.5rem;
  body[dir=rtl] & {
    padding-right: unset;
    padding-left: 2.5rem;
  }
`
const SideComponent = styled(StyledRow)`
justify-content:flex-end;
 width:60px;
`
const SideComponentMenu = styled(StyledRow) <{ supportCallType: boolean, owner: boolean }>`
justify-content:flex-end;
 width:50px;
 position:absolute;
 left:0;
 background-color: ${p => (p.owner && p.supportCallType) ? theme.color.serviceCallMessageBackLightGreen : theme.color.whiteBackground};
 width:60px;
 height:20px;
`
const StyledButton = styled(Button) <{ borderColor: string }>`
  border-color:${p => p.borderColor};
  color:${p => p.borderColor};
  border-width:1px;
`

interface MessageItemProps {
  userMessage: UserMessage
  disabledActions?: boolean
  fullWidth?: boolean
  detailsMode?: boolean
  scheduleButtonColor?: string
  publishMessage?: () => void
  onScheduleEditPressed?: () => void
}

const MessageItem: FC<MessageItemProps> = ({ userMessage, disabledActions = false, fullWidth, detailsMode, scheduleButtonColor = 'white', publishMessage = () => { }, onScheduleEditPressed = () => { } }) => {
  const { t } = useTranslation('messageItem')
  const { user, messages, newMessage, groups } = useStore()
  const supportCallType = groups?.currentGroup?.group?.type === GroupType.SUPPORT
  const [showActionArrow, setShowActionArrow] = useState(isMobile())
  const message = userMessage.message
  const onEnter = () => setShowActionArrow(true)
  const onLeave = () => setShowActionArrow(isMobile())
  const extractedPayload = extractMessagePayload(message)
  const isAfterSchedule = isAfter(new Date(), userMessage.message.schedule || 0)
  const owner = user.user?.user_id === userMessage.message.senderId
  const createdDate = format(isDate(message.created) ? message.created : parseISO(message.created), 'HH:mm')
  const expiryDate = format(isDate(message.expiry) ? message.expiry : parseISO(message.expiry), 'dd / MM / yy')
  const isSurvey = !!extractedPayload.survey
  const disabled = userMessage.message.id < 0
  return (
    <MessageListItem
      disableGutters
      owner={owner}
      supportCallType={supportCallType}

    >
      <MessageContentContainer
        className='video-width-item'
        owner={owner}
        supportCallType={supportCallType}
        fullWidth={fullWidth}
        isTypeMedia={extractedPayload.mediaType === 'PDF' || extractedPayload.mediaType === 'IMAGE' || extractedPayload.mediaType === 'VIDEO'}
        isVideo={extractedPayload.mediaType === 'VIDEO'}
      >
        <MessageContent
          onMouseEnter={disabled ? () => { } : onEnter}
          onMouseLeave={disabled ? () => { } : onLeave}
          owner={owner.toString()}
        >
          <MesageStatus
            status={userMessage.status}
            userMessage={userMessage}
            owner={owner} />
          <MessageHeader>
            <AuthorText size='lg' weight='bold'>{message.author_name}</AuthorText>
            <Tooltip title={`${t('sentDate')}`}>
              <SideComponent>
                <StatusText size='sm' weight='light'>{createdDate}</StatusText>
                <CreatedIcon />
              </SideComponent>
            </Tooltip>
            {!disabledActions && showActionArrow &&
              <SideComponentMenu owner={owner} supportCallType={supportCallType}><MessageItemActions userMessage={userMessage} /></SideComponentMenu>}
            {/* </div> */}
          </MessageHeader>
          <StyledLinkText>
            <LinkText messageText={isSurvey ? extractedPayload?.survey.question : extractedPayload.text} />
          </StyledLinkText>

          {extractedPayload?.fileUrl && extractedPayload.mediaType === 'IMAGE' && (
            <StyledImage
              onClick={() => messages.openViewerModal('image', extractedPayload.fileUrl)}
              alt={extractedPayload.fileUrl}
              src={extractedPayload.fileUrl} />
          )}

          {extractedPayload?.fileUrl && extractedPayload.mediaType === 'VIDEO' && (
            <VideoViewer 
              widthClass=".video-width-item" 
              padding={8} 
              fileUrl={extractedPayload.fileUrl} 
              fileType={null} />
          )}

          {extractedPayload?.fileUrl && extractedPayload.mediaType === 'PDF' && (
            <PdfPreview file={extractedPayload.fileUrl} />
          )}

          {isSurvey && (detailsMode ? <SurveyProgress
            userMessage={userMessage}
            answers={extractedPayload?.survey?.answers}
          /> : <SurveyMessageContainer
              userMessage={userMessage}
              answers={extractedPayload?.survey?.answers} />
          )}
          {!isAfterSchedule && (
            <Footer>
              <div>
                <Row align='stretch'>
                  <Row style={{ padding: 8, flex: 1 }}>
                    <StyledButton borderColor={scheduleButtonColor} variant='outlined' onClick={onScheduleEditPressed}>
                      <Typography>{t`messageActions:editMessage`}</Typography>
                      <Space width={1} />
                      <Create />
                    </StyledButton>
                    <Space grow />
                    <StyledButton borderColor={scheduleButtonColor} variant='outlined' onClick={publishMessage}>
                      <Typography>{t`messageActions:publishMessage`}</Typography>
                      <Space width={1} />
                      <Send />
                    </StyledButton>
                  </Row>
                </Row>
                <Text
                  textcolor={theme.color.black + 99}>{getTitleForDeliveryType(DeliveryType.CustomDateTime, new Date(userMessage.message.expiry || 0), new Date(userMessage.message.schedule || 0), t)}</Text>
              </div>
            </Footer>
          )}
          
          {message.locationRequest && <div style={{ width: '100%', display: 'flex', justifyContent: "flex-end" }}><LocationIcon /></div>}
        </MessageContent>

      </MessageContentContainer>
    </MessageListItem>
  )
}

export default observer(MessageItem)
