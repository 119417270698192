import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { IconButton, TextField } from '@material-ui/core'
import { Edit, Delete, Add, Close } from '@material-ui/icons'
import styled from 'styled-components'
import {theme, Space, Row, Text, Icon, Image, Button } from '../../ui'
import { Switch } from '@material-ui/core'
import { ConfirmButton, ModalText } from './styles'
import useStore from 'store'

const CouncilWrapper = styled.div`
  direction: ltr;
  body[dir=rtl] & {
    direction: rtl
  }
  background: white;
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem;
`

const Actions = styled.div`
  font-size: 1.6rem;
  direction: ltr;
`
const StyledRow = styled(Row)`
  width: 100%;
  flex-direction: row;
`
const RowWrapper = styled(Row)`
  flex-direction: column;
`
const StyledTextField = styled(TextField)`
  width: 100%;
  margin: 10px;
`
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`

const CouncilSectionNewItem: FC = ({ onClose }) => {
    const {t} = useTranslation('communitySettings')
    const { groups } = useStore()
    const [councilFormState, setCouncilFormState] = useState({
        name: '',
        smsCounter: 0,
        status: 1,
        extraData: { sendVideo: false }
    })

    const onSaveEvent = async () => {
      const res = await groups.createCommunityAssociatedGroups(councilFormState)
      if (res) {
        onClose()
      }
    }

    const isDisabled = () => {
      return councilFormState.name == ''
    }
   
    return (  
        <CouncilWrapper>
            <RowWrapper justify='between'>
                <StyledRow justify='between'>
                    <ConfirmButton
                        disabled={isDisabled()}
                        onClick={onSaveEvent}
                        transparent
                    >{t`save`}</ConfirmButton>
                    <div style={{display: "flex"}}>
                    <SwitchContainer>
                        <ModalText>{t`isEnable`}:</ModalText>
                        <Space/>
                        <Switch
                          color="primary"
                          value={councilFormState.status ? 1 : 0}
                          onChange={({target}) => {
                            setCouncilFormState({ ...councilFormState, status: target.checked ? 1 : 0 })
                          }}
                          checked={councilFormState.status ? 1 : 0}
                        />
                    </SwitchContainer>
                    <SwitchContainer>
                        <ModalText>{t`שליחת וידאו`}:</ModalText>
                        <Space/>
                        <Switch
                          color="primary"
                          value={councilFormState.extraData.sendVideo}
                          onChange={({target}) => {
                            setCouncilFormState({ ...councilFormState, extraData: { sendVideo: target.checked }})
                          }}
                          checked={councilFormState.extraData.sendVideo}
                        />
                    </SwitchContainer>
                    </div>


                   
                </StyledRow>
                <StyledRow justify='between'>
                    <StyledTextField
                        id={'council_name_new'}
                        required
                        label={t`name`}
                        variant="outlined"
                        value={councilFormState.name}
                        onChange={({target}) => {
                            setCouncilFormState({ ...councilFormState, name: target.value })
                        }}
                    />
                    <StyledTextField
                        id={'sms_counter_new'}
                        label={t`smsCounter`}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={true}
                        required 
                        type="number"
                        variant="outlined"
                        value={councilFormState.smsCounter}
                        onChange={({target}) => {
                          setCouncilFormState({ ...councilFormState, smsCounter: target.value })
                        }}
                    />
                </StyledRow>
            </RowWrapper>
            <Space />
        </CouncilWrapper>
    )
}

export default observer(CouncilSectionNewItem)